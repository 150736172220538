$(function() {var AjaxForm = (function () {

	var submitHandler = function (event) {
		event.preventDefault();
		var $this = $(this);
		$.ajax({
			url: $(this).attr('action'),
			type: 'POST',
			dataType: 'json',
			data: $(this).serialize(),
			processData: false,
			encode: true,
			success: function (data) {
				if (typeof data.success !== 'undefined' && data.success) {
					$this.parent().find('[data-msg="error"]').remove();
					$this.parent().find('[data-msg="success"]').removeClass('visually-hidden');
					$this.remove();
				} else if (typeof data.errors !== 'undefined') {
					console.log(data.errors);
					var $errorsContainer = $this.parent().find('[data-msg="error"]');
					// for (var key in data.errors) {
					// 	if (data.errors.hasOwnProperty(key)) {
					// 		var val = data.errors[key];
					// 		$errorsContainer.text('').append(val);
					// 	}
					// }
					$errorsContainer.removeClass('visually-hidden');
				}
			},
			error: function (xhr, error) {

			}
		});

		return false;
	};

	var bindUiActions = function () {
		$('body').on('submit', '[data-submit="ajax"]', submitHandler);
	};

	var init = function () {
		bindUiActions();
	};

	return {
		init: init
	};

})();

AjaxForm.init();

// ajax call to load more entries
$('a.ajax').click(function(e){
	e.preventDefault();

	var url = $(this).attr('data-ajax');
	if(url[url.length -1] === '/'){
		url = url.slice(0, -1);
	}
	if(url.indexOf('?') === -1){
		url = url + '?';
	}

	var offset = $('.stories-items .story').length;
	var ref = $(this);
	ref.hide();
	$('.loader').fadeIn('fast');

	$.ajax({
	    url : url + '&offset=' + offset,
	    type : 'GET',
	    success : function(data) {     
	    	if (data) {
	    		$('.loader').fadeOut('fast');
	    		$('.stories-items').append(data);
	    		if(data.replace(/\s+/g, '').length !== 0){
	    			ref.show();
	    		}
	    	}         
	    },
	    error : function(request,error){
	        console.log('Request: ' + JSON.stringify(request));
	    }
	});
});
var BioExpand = (function() {

	var _toggleBio = function(event) {
		var $this = $(this);
		var _bioCard = $this.closest('[data-card]');
		var _fullBio = _bioCard.find('[data-content="bio"]');
		var _bioHolder = _bioCard.nextAll('.bio-holder').first(); // The closest Bio Holder
		var _otherBioHolders = $('.bio-holder');

		_bioHolder.empty(); // Empty out the bio content on each click
		_otherBioHolders.empty(); // Empty out other bio holders if they are not the closest one being used
		_fullBio.clone().appendTo(_bioHolder); // Clone data into bio holder
		$('[data-link]').not($this).removeClass('-is-open').addClass('-is-closed'); // Remove 'is-open' class on all links that aren't the one clicked
		$(this).toggleClass('-is-closed -is-open'); // Toggle link open/closed clas

		// setTimeout(function() {
		// 	$('html, body').animate({
		// 		scrollTop: $this.offset().top
		// 	}, 450);
		// }, 500);

		// If the bioHolder is closed…
		if (_bioHolder.hasClass('-is-closed')) {
			_bioHolder.toggleClass('-is-closed -is-open'); // Toggle open/closed for bioHolder
		}

		// If the bioHolder is NOT closed and the link clicked is NOT open…
		if ($this.hasClass('-is-closed')) {
			_bioHolder.toggleClass('-is-open -is-closed');
		}

		event.preventDefault();
	};

	var _bindUiActions = function() {
		$('body').on('click', '[data-link="bio"]', _toggleBio);
	};

	var init = function() {
		_bindUiActions();
	};

	return {
		init: init
	};

})();

BioExpand.init();

// ---------------------------------------------------------------------------------------------------
// Make dynamic 'Bio Content Holders'
// ---------------------------------------------------------------------------------------------------

var _bioCards = $('.layout-grid-bio-cards .card-bio').length; // Count number of total cards
var _bioHolderXL = '<div class="bio-holder -is-closed" data-bio="holder-xl"></div>'; // The Dynamically inserted bio holder
var _bioHolderL = '<div class="bio-holder -is-closed" data-bio="holder-l"></div>'; // The Dynamically inserted bio holder
var _bioHolderS = '<div class="bio-holder -is-closed" data-bio="holder-s"></div>'; // The Dynamically inserted bio holder
var _dataLink = $('[data-link]'); // Remove the '-is-open' class on a link on screen change

// Media Queries!
enquire
	// Small and Medium, 2 Items
	.register('screen and (max-width: 767px)', {
		match: function() {
			setTimeout(function() {
				$('.layout-grid-bio-cards > .card-bio:nth-child(even)').after(_bioHolderS);
			}, 300);
			// If total number of cards is not divisible by 2, insert row at the end
			if (!_bioCards % 2 === 0) {
				setTimeout(function() {
					$('.layout-grid-bio-cards > .card-bio:last-child').after(_bioHolderS);
				}, 300);
			}
			//console.log('small set');
		},
		unmatch: function() {
			$('[data-bio="holder-s"]').remove();
			_dataLink.removeClass('-is-open').addClass('-is-closed');
			//console.log('small unset');
		}
	})
	// Large only, 4 items
	.register('screen and (max-width: 1023px) and (min-width: 768px)', {
		match: function() {
			setTimeout(function() {
				$('.layout-grid-bio-cards > .card-bio:nth-child(4n)').after(_bioHolderL);
			}, 300);
			// If total number of cards is not divisible by 4, insert row at the end
			if (!_bioCards % 4 === 0) {
				setTimeout(function() {
					$('.layout-grid-bio-cards > .card-bio:last-child').after(_bioHolderL);
				}, 300);
			}
			//console.log('large set');
		},
		unmatch: function() {
			$('[data-bio="holder-l"]').remove();
			_dataLink.removeClass('-is-open').addClass('-is-closed');
			//console.log('large unset');
		}
	})
	// Xlarge up, 5 items
	.register('screen and (min-width: 1024px)', {
		match: function() {
			setTimeout(function() {
				$('.layout-grid-bio-cards > .card-bio:nth-child(5n)').after(_bioHolderXL);
			}, 300);
			// If total number of cards is not divisible by 5, insert row at the end
			if (!_bioCards % 5 === 0) {
				setTimeout(function() {
					$('.layout-grid-bio-cards > .card-bio:last-child').after(_bioHolderXL);
				}, 300);
			}
			//console.log('Xlarge set');
		},
		unmatch: function() {
			$('[data-bio="holder-xl"]').remove();
			_dataLink.removeClass('-is-open').addClass('-is-closed');
			//console.log('Xlarge unset');
		}
	})
;

var CtaCookie = (function() {

    var $alert = $('.cta-cookie'); // The active item
    var $button = $('.cta-cookie a.button'); // UL of nav items

    var getCookie = function(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) {
          return parts.pop().split(";").shift();
        } else {
          return false;
        }
    };

    var setCookie = function(name, value, days){
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        var expires = "; expires=" + date.toGMTString();
        document.cookie = name + "=" + value + expires;
    };

    var _bindUiActions = function() {
        $button.on( 'click', function( ev ) {
            ev.preventDefault();

            setCookie('ctaCookie', 1, 100);
            $alert.removeClass( '-show' );
        } );

        if ( !getCookie( 'ctaCookie' ) ) {
            $alert.addClass( '-show' );
        } else {
            $alert.remove();
        }
    };

    var init = function() {
        _bindUiActions();
    };

    return {
        init: init
    };

})();

CtaCookie.init();

//  $$$$$$$$\             $$\                                             $$\       $$\       $$\           $$\
//  $$  _____|            $$ |                                            $$ |      $$ |      \__|          $$ |
//  $$ |      $$\   $$\ $$$$$$\    $$$$$$\   $$$$$$\  $$$$$$$\   $$$$$$\  $$ |      $$ |      $$\ $$$$$$$\  $$ |  $$\  $$$$$$$\
//  $$$$$\    \$$\ $$  |\_$$  _|  $$  __$$\ $$  __$$\ $$  __$$\  \____$$\ $$ |      $$ |      $$ |$$  __$$\ $$ | $$  |$$  _____|
//  $$  __|    \$$$$  /   $$ |    $$$$$$$$ |$$ |  \__|$$ |  $$ | $$$$$$$ |$$ |      $$ |      $$ |$$ |  $$ |$$$$$$  / \$$$$$$\
//  $$ |       $$  $$<    $$ |$$\ $$   ____|$$ |      $$ |  $$ |$$  __$$ |$$ |      $$ |      $$ |$$ |  $$ |$$  _$$<   \____$$\
//  $$$$$$$$\ $$  /\$$\   \$$$$  |\$$$$$$$\ $$ |      $$ |  $$ |\$$$$$$$ |$$ |      $$$$$$$$\ $$ |$$ |  $$ |$$ | \$$\ $$$$$$$  |
//  \________|\__/  \__|   \____/  \_______|\__|      \__|  \__| \_______|\__|      \________|\__|\__|  \__|\__|  \__|\_______/
//
//
//

var ExternalLinks = (function() {

	var _laterDood = function(event) {
		var url = $(this).attr('href');
		event.preventDefault();
		window.open(url, '_blank');
	};

	var _bindUiActions = function() {
		$('body').on('click', '[data-link="external"]', _laterDood);
	};

	var init = function () {
		// Bind UI actions
		_bindUiActions();
	};

	return {
		init: init
	};

})();

ExternalLinks.init();

var videoContainer = $('[data-embed="video"]');

if (videoContainer.length) {
	videoContainer.fitVids();
}

var FormValidator = ( function () {

	var _form = {},
		_formSelector = '[data-form="validate"]',
		_defaults = {}
	;

	var init = function () {
		_form = $(_formSelector).validate(_defaults);
	};

	return {
		init: init
	};

})();

FormValidator.init();

var LanguageSwitcherMobile = (function() {

	var _showLanguageSwitcher = function() {
		var $langaugePane = $('[data-content="language-switcher-mobile"]');
		$langaugePane.toggleClass('-is-hidden -is-visible');
	};

	var _hideLanguageSwitcher = function() {
		var $langaugePane = $('[data-content="language-switcher-mobile"]');
		$langaugePane.toggleClass('-is-visible -is-hidden');
	};

	var _bindUiActions = function() {
		$('body').on('click', '[data-trigger="language-switcher-mobile"]', _showLanguageSwitcher);
		$('body').on('click', '[data-close="language-switcher-mobile"]', _hideLanguageSwitcher);
	};

	var init = function() {
		_bindUiActions();
	};

	return {
		init: init
	};

})();

LanguageSwitcherMobile.init();

var ModuleBlockCaption = (function() {

	// Global Variables
	var _module = $('[data-item="module"]'); // Wrapping Module element
	var _moduleItems = $('[data-items="module-blocks"]'); // The parent container for module blocks
	var _wrapper = '.module-items-outer:not(".flickity-enabled")'; // Need to only do click events when flickity is not enabled
	var _dataSelector = '[data-modulecaption]'; // Look for link with modulecaption
	var _captionBox = $('[data-module="caption-box"]'); // Caption Box

	var _showCaption = function() {
		var $this = $(this);
		var $captionBlock = $this.closest(_module).find(_captionBox); // Find the closest caption block
		var $selectedCaption = $this.find('[data-caption]').html(); // Get caption text

		$captionBlock.html($selectedCaption); // Replace Caption block text with first item caption
	};

	// Only run if data-modulecaption exists
	if (_dataSelector.length) {
		// Get the first caption of each module group to display in large screen caption areas
		_moduleItems.each(function() {
			var $this = $(this);
			var $initialCaption = $this.find('[data-modulecaption]').first().find('[data-caption]').html(); // Find first caption
			var $captionBlock = $this.closest(_module).find(_captionBox); // Find the closest caption block

			$captionBlock.html($initialCaption); // Replace Caption block text with first item caption
		});
	}

	var _bindUiActions = function() {
		// Only run if data-modulecaption exists
		if (_dataSelector.length) {
			$('body').on('click', _wrapper + ' ' + _dataSelector, _showCaption);
		}
	};

	var init = function() {
		_bindUiActions();
	};

	return {
		init: init
	};

})();

ModuleBlockCaption.init();

var ModuleBlockFlickity = (function() {

	// Global Variables
	var _flickity = $('[data-flickity]'); // Look for data-flickity in markup
	var _module = $('[data-item="module"]'); // Wrapping Module element

	// Update Visuals on Flickity Scroll
	var _moduleItemsVisual = function(selectedElement) {
		var $this = $(this);
		var $visualContainer = $this.closest(_module);
		var $flkty = $this.data('flickity');
		var $flktyData = $($flkty.selectedElement);

		var $blockItem = $flktyData.find('[data-moduleitem]').data('moduleitem');

		var $blockItemVisual = $visualContainer.find('[data-modulegraphic="'+$blockItem+'"]');
		var $blockItemVisualInactive = $visualContainer.find('[data-modulegraphic]');

		$blockItemVisualInactive.removeClass('is-visible').addClass('is-hidden');
		$blockItemVisual.toggleClass('is-hidden is-visible');

	};

	var _bindUiActions = function() {
		_flickity.on('scroll.flickity', _moduleItemsVisual);
	};

	var init = function() {
		_bindUiActions();
	};

	return {
		init: init
	};

})();

ModuleBlockFlickity.init();

//$('.module-carousel').on('scroll.flickity', function(event, progress) {
//	console.log( 'Flickity scrolled ' + progress * 100 + '%' );
//});

var ModuleBlockSelect = (function() {

	// Global Variables
	var _module = $('[data-item="module"]'); // Wrapping Module element
	var _moduleItems = $('[data-items="module-blocks"]'); // The parent container for module blocks
	var _moduleItemsVisual = $('[data-items="module-visuals"]'); // The parent container for visuals related to module blocks
	var _wrapper = '.module-items-outer:not(".flickity-enabled")'; // Need to only do click events when flickity is not enabled
	var _dataSelector = '[data-moduleitem]'; // The module block

	// Active Class
	var _makeActive = function() {
		var $this = $(this);
		var $block = $('[data-moduleitem]');

		$this.closest(_moduleItems).find($block).not(this).removeClass('active'); // Remove Active class on all items not clicked, context of parent item
		$this.addClass('active'); // Add 'active' class to clicked item
	};

	// Show/Hide visual on click
	var _visualSwap = function() {
		var $this = $(this); // context: [data-moduleitem] -- link
		var $dataElement = $this.data('moduleitem');
		var $visualItems = $this.closest(_module).find('[data-modulegraphic]'); // Traverse up to parent element and then find the individual module graphics
		var $visualItemActive = $this.closest(_module).find('[data-modulegraphic="' + $dataElement + '"]'); // Traverse up to parent element, get the data from the clicked link, it's data should match the modulegraphic, make it visible

		$visualItems.removeClass('is-visible').addClass('is-hidden'); // Add 'is-hidden' class to all images within the parent
		$visualItemActive.removeClass('is-hidden').addClass('is-visible'); // Match the data element and show graphic module
	};

	// Make first module block active on page load
	_moduleItems.each(function() {
		$(this).find('[data-moduleitem]').first().addClass('active');
	});

	// Make first module visual visible on page load
	 _moduleItemsVisual.each(function() {
		 $(this).find('[data-modulegraphic]').first().removeClass('is-hidden').addClass('is-visible');
	 });

	var _bindUiActions = function() {
		$('body').on('click', _wrapper + ' ' + _dataSelector, _makeActive);
		$('body').on('click', _wrapper + ' ' + _dataSelector, _visualSwap);
	};

	var init = function() {
		_bindUiActions();
	};

	return {
		init: init
	};

})();

ModuleBlockSelect.init();

var IconShowHide = (function() {

	var _animateIcon = function() {
		var $this = $(this);

		$this.toggleClass('-is-closed -is-open');
	};

	var _bindUiActions = function() {
		$('body').on('click', '[data-nav="showhide"]', _animateIcon);
	};

	var init = function() {
		_bindUiActions();
	};

	return {
		init: init
	};

})();

IconShowHide.init();

var NavMainMobile = (function() {

	var _showChildNav = function() {
		var $this = $(this);

		$this.toggleClass('-is-closed -is-open'); // Toggle closed/open class for hamburger label
	};

	var _childNavL1 = function() {
		var $this = $(this);
		var $checkboxL1 = $('[data-nav="childL1"]');

		$checkboxL1.not($this).prop('checked', false); // Close any open subnavs that is not the one clicked
		$checkboxL1.not($this).next('label').removeClass('-is-open').addClass('-is-closed'); // Remove the '-is-open' class on labels abd add '-is-closed'
	};

	var _childNavL2 = function() {
		var $this = $(this);
		var $checkboxL2 = $('[data-nav="childL2"]');

		$checkboxL2.not($this).prop('checked', false); // Close any open subnavs that is not the one clicked
		$checkboxL2.not($this).next('label').removeClass('-is-open').addClass('-is-closed'); // Remove the '-is-open' class on labels abd add '-is-closed'
	};

	var _bindUiActions = function() {
		$('body').on('click', '[data-nav="burger"]', _showChildNav);
		$('body').on('click', '[data-nav="childL1"]', _childNavL1);
		$('body').on('click', '[data-nav="childL2"]', _childNavL2);
	};

	var init = function() {
		_bindUiActions();
	};

	return {
		init: init
	};

})();

NavMainMobile.init();



// var NavMainWidescreen = (function() {

// 	var _showChildNav = function() {
// 		var $this = $(this);
// 		var $data = $this.data('navparent'); // Get the data value of 'data-navparent' on clicked element
// 		var $nav = $('[data-navchild="' + $data + '"]'); // Find the nav child that has data-navchild which matches data-navparent
// 		var $navChild = $('[data-navchild]'); // All the Navchildren say whooooaaaaa

// 		// First, let's check if there is another subnav open and that we aren't clicking the same toggle of the already-open element
// 		if ( $navChild.hasClass('-is-open') && (!$nav.hasClass('-is-open')) ) {
// 			$('[data-navchild]').removeClass('-is-open').addClass('-is-closed'); // Hide the visible, old nav
// 			$('[data-navparent]').removeClass('-is-open').addClass('-is-closed'); // We also need to toggle the nav parent class to show the correct state of the arrow
// 			setTimeout(function() {
// 				$nav.toggleClass('-is-closed -is-open'); // Toggle open/closed for sub-nav but wait for 600 ms for the old one to close w/ css animation
// 				$this.toggleClass('-is-closed -is-open'); // Toggle closed/open class for clicked link. Used to animate arrow.
// 			}, 400);
// 		} else {
// 			$nav.toggleClass('-is-closed -is-open'); // Toggle open/closed for sub-nav
// 			$this.toggleClass('-is-closed -is-open'); // Toggle closed/open class for clicked link. Used to animate arrow.
// 		}
// 	};

// 	var _bindUiActions = function() {
// 		$('body').on('click', '[data-navparent]', _showChildNav);
// 	};

// 	var init = function() {
// 		_bindUiActions();
// 	};

// 	return {
// 		init: init
// 	};

// })();

// NavMainWidescreen.init();

function clearUpNavOpen(){
	// $('body').removeClass('no-scroll');
	$('.redesign-nav-main .nav-dropdown').removeClass('open');
	$('.nav-dropdown_content').removeClass('open');
	$('.redesign-nav-main .nav-dropdown').find('span.text > span').text('+ ');
}

$('.redesign-nav-main .nav-dropdown').click(function(e){
	// if dropdown is open, go to the navigation link, otherwise open the dropdown
	if (!$(this).hasClass('open')) {
		e.preventDefault();
		clearUpNavOpen();
		// $('body').toggleClass('no-scroll');
		$(this).toggleClass('open');
		$(this).find('.nav-dropdown_content').toggleClass('open');
	}
	var plus = $(this).find('span.text > span');
	if (plus.length) {
		plus.text('- ');
	}
});

$(window).click(function(e){
	// close dropdown if clicked outside
	if($(e.target).closest('.nav-item').length === 0){
		if ($('.redesign-nav-main .nav-dropdown').hasClass('open')) {
			clearUpNavOpen();
		}
	}
	if($(e.target).closest('.language-switcher').length === 0){
		$('.language-switcher').removeClass('open');
	}
});

$('.language-switcher').click(function(){
	$(this).toggleClass('open');
});

$('.dropdown-subnavigation .tab-navigation .tab-title').click(function(){
	var tabNum = $(this).attr('data-tab');
	$('.dropdown-subnavigation .tab-navigation .tab-title').removeClass('active');
	$('.tab').removeClass('active');

	$(this).addClass('active');
	$('div[data-tab="' + tabNum +'"]').addClass('active');
});

$('.nav-main.-mobile .dropdown-subnavigation .tab-navigation .tab-title').click(function(){
	$(this).closest('.tab-block').toggleClass('active');
});


var NavSection = (function() {

	var $activeNav = $('.nav-sections .item.-active'); // The active item
	var $nav = $('.nav-sections .items'); // UL of nav items

	function setNavPosition() {
		var $activePosition = $activeNav.offset();
		$nav.scrollLeft($activePosition.left); // Scroll the nav to the beginning of the active item, with the width of the header as an offset
	}

	var _bindUiActions = function() {
		if ($activeNav.length) {
			setNavPosition();
		}
	};

	var init = function() {
		_bindUiActions();
	};

	return {
		init: init
	};

})();

NavSection.init();

var NavSolutions = (function() {

	var $activeNav = $('.nav-solutions-subnav .nav-item.-is-active'); // The active item
	var $nav = $('.nav-solutions-subnav .nav-group'); // UL of nav items

	function setNavPosition() {
		var $activePosition = $activeNav.offset();
		$nav.scrollLeft($activePosition.left); // Scroll the nav to the beginning of the active item
	}

	var _bindUiActions = function() {
		if ($activeNav.length) {
			setNavPosition();
		}
	};

	var init = function() {
		_bindUiActions();
	};

	return {
		init: init
	};

})();

NavSolutions.init();

/**
 * NavSticky
 * @type {{init}}
 */
var NavSticky = (function () {

	// Set element variables.
	var mainHeader = $('.js-auto-hide-header'),
		secondaryNavigation = $('.js-secondary-nav'),
		//this applies only if secondary nav is below intro section
		belowNavHeroContent = $('.sub-nav-hero'),
		headerHeight = mainHeader.height();

	// Set scrolling variables.
	var scrolling = false,
		previousTop = 0,
		currentTop = 0,
		scrollDelta = 10,
		scrollOffset = 150;

	var checkSimpleNavigation = function (currentTop) {
		// Dynamically set height for open/closed navs
		var $navMainHeight = $('.nav-main').outerHeight();
		var $navSecondaryHeight = $('.nav-secondary').outerHeight();

		$('.js-auto-hide-header').css({
			'height': $navMainHeight + $navSecondaryHeight + 'px'
		});
		//there's no secondary nav or secondary nav is below primary nav
		if (previousTop - currentTop > scrollDelta) {
			//if scrolling up...
			mainHeader.removeClass('is-hidden');
		} else if( currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
			//if scrolling down...
			mainHeader.addClass('is-hidden');
		}
	};

	var checkStickyNavigation = function (currentTop) {
		//secondary nav below intro section - sticky secondary nav
		var secondaryNavOffsetTop = belowNavHeroContent.offset().top - secondaryNavigation.height() - mainHeader.height();

		if (previousTop >= currentTop ) {
			//if scrolling up...
			if( currentTop < secondaryNavOffsetTop ) {
				//secondary nav is not fixed
				mainHeader.removeClass('is-hidden');
				secondaryNavigation.removeClass('fixed slide-up');
				belowNavHeroContent.removeClass('secondary-nav-fixed');
			} else if( previousTop - currentTop > scrollDelta ) {
				//secondary nav is fixed
				mainHeader.removeClass('is-hidden');
				secondaryNavigation.removeClass('slide-up').addClass('fixed');
				belowNavHeroContent.addClass('secondary-nav-fixed');
			}

		} else {
			//if scrolling down...
			if( currentTop > secondaryNavOffsetTop + scrollOffset ) {
				//hide primary nav
				mainHeader.addClass('is-hidden');
				secondaryNavigation.addClass('fixed slide-up');
				belowNavHeroContent.addClass('secondary-nav-fixed');
			} else if( currentTop > secondaryNavOffsetTop ) {
				//once the secondary nav is fixed, do not hide primary nav if you haven't scrolled more than scrollOffset
				mainHeader.removeClass('is-hidden');
				secondaryNavigation.addClass('fixed').removeClass('slide-up');
				belowNavHeroContent.addClass('secondary-nav-fixed');
			}

		}
	};

	var autoHideHeader = function () {
		currentTop = $(window).scrollTop();

		if ( belowNavHeroContent.length > 0 ) {
			checkStickyNavigation(currentTop); // secondary navigation below intro
		} else {
			checkSimpleNavigation(currentTop);
		}

		previousTop = currentTop;
		scrolling = false;
	};

	var bindUiActions = function () {
		$(window).on('scroll', function () {
			if( !scrolling ) {
				scrolling = true;
			} else {
				if (!window.requestAnimationFrame) {
					setTimeout(autoHideHeader, 250);
				} else {
					requestAnimationFrame(autoHideHeader);
				}
			}
		});

		$(window).on('resize', function(){
			headerHeight = mainHeader.height();
		});
	};

	var init = function () {
		// Setting padding top to account for fixed header on page load
		enquire.register('screen and (min-width: 1024px)', {
			match: function() {
				$('.layout-outer').css({
					'padding-top': $('.js-auto-hide-header').outerHeight() + 'px'
				});
			},
			unmatch: function() {
				$('.layout-outer').css({
					'padding-top': '4em' // Set padding to offset fixed mobile nav when smaller breakpoints
				});
			}
		});

		bindUiActions();
	};

	return {
		init: init
	};

})();

NavSticky.init();

$('[data-link="noclick"]').on('click', function(event) {
	event.preventDefault();
});
});